.about-nav
	.is-lower
		ul
			display: flex
			align-items: center
			justify-content: center
			li
				display: flex
				align-items: center
				em
					font-size: r(32px)
				span
					margin-left: r(10px)
					font-size: r(18px)
					font-weight: 600
					a:hover
						color: color(hover)
				& + li
					margin-left: r(120px)
	.nav-item
		position: relative
		display: block
		.img
			+img-scale(75%)
		.title
			position: absolute
			top: 50%
			text-align: center
			transform: translateY(-50%)
			color: #fafafa
			font-size: r(18px)
			width: 100%
			padding: 0 15px
	@media screen and ( max-width: 767px )
		.nav-item
			.title
				font-size: 5.3333333333vw
		.is-lower
			display: block
			ul
				li
					& + li
						margin-left: 0
						margin-top: 1rem
						padding-top: 1rem
						border-top: 1px solid #e1e1e1
