.p-spec-table
	padding-bottom: 4vw
	position: relative
	margin: 0 auto
	border-bottom: 1px solid #e1e1e1
	.p-spec-table__float
		position: relative
		z-index: 10
		height: 0
		pointer-events: none
		.p-spec-table__float__inner
			will-change: min-height
			&.is-affixed
				.p-spec-table__wrapper
					.p-spec-table__inner
						opacity: 1
			.inner-wrapper-sticky
				transform: translate3d(0,0,0)
				will-change: position,transform
				> *
					transition-duration: .3s
					transition-property: transform
			.p-spec-table__wrapper
				position: relative
				.p-spec-table__td
					position: relative
					background-color: #f0f0f0
					padding-top: 5.3333333333vw
					padding-bottom: 5.3333333333vw
					.c-content-figure
						display: none
					p
						text-align: center
						font-size: 3.2vw
				.p-spec-table__inner
					overflow: hidden
					padding-bottom: 8vw
					transition-duration: .3s
					transition-property: transform,opacity
					opacity: 0
					&::before
						position: absolute
						bottom: 0
						left: 0
						display: block
						width: 100%
						height: 2.2222222222em
						content: ""
						background: linear-gradient(to bottom,rgba(0,0,0,.1)0,rgba(0,0,0,.03) 50%,rgba(0,0,0,.015) 65%,rgba(0,0,0,.0075) 75.5%,rgba(0,0,0,.0037) 82.85%,rgba(0,0,0,.0019) 88%,transparent 100%)
	.p-spec-table__title
		text-align: center
	.p-spec-table__tr
		display: flex
		align-items: flex-start
		.p-spec-table__td
			box-sizing: border-box
			width: 100%
			letter-spacing: -.02em
			font-size: 3.2vw
			padding-right: 4vw
			padding-left: 4vw
			ul
				li
					& + li
						margin-top: 3.2vw
			.c-content-figure
				width: 24vw
				.c-content-figure__wrapper
					&:before
						display: block
						padding-top: 100%
						content: ""
	.p-spec-table__fields
		&.is-child
			h4
				letter-spacing: -.02em
				font-size: 3.2vw
				margin-bottom: 1.3333333333vw
			& + .is-child
				border-top: 1px solid #e1e1e1
				padding-top: 4vw
				margin-top: 4vw
		&.is-grand
			margin-top: 4vw
			h5
				letter-spacing: -.02em
				font-size: 3.2vw
				margin-bottom: 1.3333333333vw
		&.is-parent
			margin-top: 1.1111111111em
			.p-spec-table__header
				h3
					letter-spacing: -.02em
					border-bottom: 1px solid #191919
					font-size: 3.2vw
					margin-bottom: 4vw
					padding-bottom: 4vw
			& + .is-parent
				border-top: 1px solid #e1e1e1
				padding-top: 4vw
	@media screen and ( min-width: 768px )
		width: 50vw
		padding-bottom: 1.5625vw
		.p-spec-table__float
			.p-spec-table__float__inner
				.p-spec-table__wrapper
					position: relative
					.p-spec-table__td
						padding: 1.6666666667em 0
						p
							font-size: 1.09375vw
						.c-content-figure
							display: block
							& + p
								margin-top: 1.5625vw
					.p-spec-table__inner
						padding-bottom: 2.2222222222em

		.p-spec-table__tr
			.p-spec-table__td
				font-size: 1.09375vw
				padding-right: 1.5625vw
				padding-left: 1.5625vw
				ul
					li
						& + li
							margin-top: 1.09375vw
				.c-content-figure
					width: 9.375vw
		.p-spec-table__fields
			&.is-grand
				margin-top: 1.5625vw
				h5
					font-size: 1.09375vw
					margin-bottom: .390625vw
			&.is-child
				h4
					font-size: 1.09375vw
					margin-bottom: .390625vw
				& + .is-child
					padding-top: 1.5625vw
					margin-top: 1.5625vw
			&.is-parent
				.p-spec-table__header
					h3
						font-size: 1.09375vw
						margin-bottom: 1.5625vw
						padding-bottom: 1.5625vw
				& + .is-parent
					padding-top: 1.5625vw
	@media screen and ( min-width: 1280px )
		width: 640px
		padding-bottom: 20px
		font-size: 18px
		.p-spec-table__float
			.p-spec-table__float__inner
				.p-spec-table__wrapper
					.p-spec-table__inner
						padding-bottom: 0
					.p-spec-table__td
						p
							font-size: 14px
						.c-content-figure
							& + p
								margin-top: 20px
		.p-spec-table__tr
			.p-spec-table__td
				font-size: 14px
				padding-right: 20px
				padding-left: 20px
				ul
					li
						& + li
							margin-top: 14px
				.c-content-figure
					width: 120px
		.p-spec-table__fields
			&.is-grand
				margin-top: 20px
				h5
					font-size: 14px
					margin-bottom: 5px
			&.is-child
				h4
					font-size: 14px
					margin-bottom: 5px
				& + .is-child
					padding-top: 20px
					margin-top: 20px
			&.is-parent
				.p-spec-table__header
					h3
						font-size: 14px
						margin-bottom: 20px
						padding-bottom: 20px
				& + .is-parent
					padding-top: 20px

body:not(.ui-hidden)
	.p-spec-table__float__inner.is-affixed
		.p-spec-table__wrapper
			transform: translateY(5.5555555556em)
	@media screen and ( max-width: 767px )
		.p-spec-table__float__inner.is-affixed
			.p-spec-table__wrapper
				transform: translateY(20.4vw)