.product-detail-wrapper
	position: relative
	@media screen and ( min-width: 768px )
		margin-top: 5.5555555556em
		padding-top: 3.3333333333em

.product-detail-content
	@media screen and ( max-width: 767px )
		margin-top: 32vw
	@media screen and ( min-width: 1280px )
		font-size: 18px

.product-detail-img-wrapper
	margin-top: r(148px)
	text-align: center
	width: 120px
	margin-left: auto
	margin-right: auto
	p
		font-size: r(14px)
		margin-top: r(14px)
	.product-detail-img
		+img-scale(100%)
		img
			object-fit: contain
	@media screen and ( max-width: 767px )
		width: 24vw
		margin-top: 40.8vw

.product-detail-img-caption
	margin-top: 4vw
	font-size: 1em
	@media screen and ( min-width: 768px )
		margin-top: 1.11111111111em
	@media screen and ( min-width: 1280px )
		margin-top: r(20px)
		font-size: r(18px)

.product-detail-spec-tables
	.spec-note,.p-spec-notes
		margin-top: 20px
		font-size: 14px
		opacity: 0.5
	table
		width: 100%
		small
			font-size: 80%
		thead
			th
				font-size: 14px
				padding-bottom: 20px
				border-bottom: 1px solid #191919
				font-weight: 600
				text-align: left
		tbody
			tr
				td
					font-size: 14px
					padding: 20px
					border-bottom: 1px solid #e1e1e1
					h3
						margin-left: -20px
						font-size: 14px
					h4,h5
						font-weight: 600
						font-size: 14px
						margin-bottom: 5px
					
					* + h5, * + h4
						margin-top: 20px
					ul
						li
							& + li
								margin-top: 20px
		& + table
			margin-top: 20px
	@media screen and ( max-width: 1280px )
		.spec-note
			font-size: 1.09375vw
		table
			thead
				th
					font-size: 1.09375vw
					padding-bottom: 1.5625vw
			tbody
				tr
					td
						font-size: 1.09375vw
						padding: 1.5625vw
						h3
							margin-left: -1.5625vw
							font-size: 1.09375vw
						h4,h5
							font-size: 1.09375vw
						* + h5
							margin-top: 1.5625vw
						ul
							li
								& + li
									margin-top: 1.5625vw
			& + table
				margin-top: 1.5625vw
	@media screen and ( max-width: 767px )
		margin-top: 6.25vw
		.spec-note
			font-size: 3.2vw
		table
			thead
				th
					font-size: 3.2vw
					padding-bottom: 4vw
			tbody
				tr
					td
						font-size: 3.2vw
						padding: 4vw
						h3
							margin-left: -4vw
							font-size: 3.2vw
						h4,h5
							font-size: 3.2vw
						* + h5
							margin-top: 4vw
						ul
							li
								& + li
									margin-top: 4vw
			& + table
				margin-top: 4vw

.product-lineup-item
	position: relative
	& + .product-lineup-item
		margin-top: 16vw
	.product-lineup-item-img-wrap
		width: 40vw
		margin: 0
	.product-lineup-item-img
		+img-scale(100%)
		img
			object-fit: contain
	h3
		margin-top: 4vw
		font-size: 5.3333333333vw
		text-align: center
	.product-lineup-item-sublist
		margin-top: 8vw
	.product-lineup-item-sub
		display: flex
		flex-wrap: wrap
		justify-content: space-between
	.product-lineup-item-sub
		& + .product-lineup-item-sub
			border-top: 1px solid #dcdcdc
			margin-top: 8vw
			padding-top: 8vw
		.buttons
			display: flex
			align-items: center
			width: calc((100% - 4vw)*.5)
			margin-top: 0
			.button
				width: 100%
	.product-lineup-item-sub-main
		width: calc((100% - 4vw)*.5)
		h4
			display: none
	.sales-info
		&.is-unique
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			font-size: .7777777778em
			margin-top: 8vw
			> *
				margin-bottom: .7142857143em
			dt
				width: 31.8181818182%
			dd
				width: 63.6363636364%
		&:not(.is-unique)
			dt
				display: none
	.product-lineup-item-sub-title
		display: block
		width: 100%
		margin-bottom: 4vw
		font-weight: 600
		&::before
			display: block
			content: attr(data-title)
	.product-lineup-item-desc
		font-size: .7777777778em
		line-height: 1.8
		margin-top: 2.8571428571em
		letter-spacing: -.02em
	@media screen and ( min-width: 768px )
		display: flex
		justify-content: space-between
		margin-right: auto
		margin-left: auto
		& + .product-lineup-item
			margin-top: 6.25vw
		h3
			font-size: 20px
			margin-top: 0
			text-align: left
		.product-lineup-item-img-wrap
			width: 12.5vw
			min-height: 12.5vw
		.product-lineup-item-container
			width: 34.375vw
			display: flex
			flex-direction: column
			justify-content: center
		.product-lineup-item-sublist
			margin-top: 3.125vw
		.product-lineup-item-sub-main
			width: 18.75vw
			h4
				display: block
		.product-lineup-item-sub
			.buttons
				width: 12.5vw
			& + .product-lineup-item-sub
				margin-top: 3.125vw
				padding-top: 3.125vw

		.product-lineup-item-sub-title
			display: none
		.sales-info
			margin-top: 1.5625vw
			&.is-unique
				margin-top: 3.125vw
	@media screen and ( min-width: 1280px )
		width: 640px
		font-size: 18px
		& + .product-lineup-item
			margin-top: 80px
		.product-lineup-item-img-wrap
			width: 160px
			min-height: 160px
		.product-lineup-item-container
			width: 440px
		.product-lineup-item-sublist
			margin-top: 40px
		.product-lineup-item-sub-main
			width: 240px
		.product-lineup-item-sub
			.buttons
				width: 160px
			& + .product-lineup-item-sub
				margin-top: 40px
				padding-top: 40px
		.sales-info
			margin-top: 20px !important

.product-support
	.product-support-nav-list
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		li
			position: relative
			> div
				display: flex
				align-items: center
				height: 100%
				span
					font-size: r(40px)
					display: inline-flex
					align-items: center
					justify-content: center
					width: 1em
					margin-right: .5em
				h4
					line-height: 1.5
					width: calc(100% - 3.3333333333em)
					text-align: left
	@media screen and ( max-width: 767px )
		.product-detail-img-wrapper
			width: 40vw

		.product-support-nav-list
			li
				width: 100%
				& + li
					margin-top: 4vw
					padding-top: calc(4vw + 1px)
					border-top: 1px solid #dcdcdc
	@media screen and ( min-width: 768px )
		.product-detail-img-wrapper
			width: 8.8888888888em
		.product-support-nav-list
			li
				width: 50%
				margin-top: 1.1111111111em
				padding-top: calc(1.1111111111em + 1px)
				> div
					width: 87.5%
				&:nth-child(2n)
					> div
						margin-left: auto
				&:nth-child(2n+1)
					> div
						&:before
							position: absolute
							top: 66%
							right: 0
							display: block
							width: 1px
							height: 100%
							content: ""
							transform: translateY(-50%)
							background-color: #dcdcdc
				&:nth-child(2n+3)
					&:after
						position: absolute
						top: 0
						left: 0
						display: block
						width: 200%
						height: 1px
						content: ""
						background-color: #dcdcdc

	@media screen and ( min-width: 1280px )
		.product-detail-img-wrapper
			width: 160px
		.product-support-nav-list
			li
				> div
					position: relative
				&:nth-child(1),&:nth-child(2)
					margin-top: 0
					padding-top: 0
					border-top: none
				&:nth-child(2n+1)
					> div
						&:before
							top: 50%
							right: -2.2222222222em

.product-faq
	.product-faq-list
		.product-faq-list-item-wrapper
			display: flex
			align-items: center
			justify-content: space-between
			.product-faq-list-item-container
				h4
					display: inline-block
					margin: -1em
					padding: 1em
			.c-icon
				font-size: .7777777778em
				width: 1em
				margin-left: 1em
				transition-duration: .3s
				transform: rotateX(0deg)
				color: #dcdcdc
		.product-faq-list-item
			& + .product-faq-list-item
				margin-top: 2.2222222222em
				padding-top: 2.2222222222em
				border-top: 1px solid #dcdcdc
	@media screen and ( max-width: 767px )
		.product-faq-list
			.product-faq-list-item
				& + .product-faq-list-item
					margin-top: 6.6666666667vw
					padding-top: 6.6666666667vw
	@media screen and ( min-width: 1280px )
		.product-faq-list-item
			font-size: 18px

.product-contact
	border: 1px solid #191919
	padding: 8vw
	.section-heading
		.c-icon
			font-size: 4.4444444444em
		h2
			margin-top: 1.1111111111em
	.rich-text
		margin-top: 4vw
	@media screen and ( min-width: 768px )
		padding: 4.4444444444em
		.rich-text
			margin-top: 1.5625vw
	@media screen and ( min-width: 1280px )
		font-size: 18px
		.rich-text
			margin-top: r(20px)

.product-catalog-item
	text-align: center
	& + .product-catalog-item
		margin-top: 8vw
	> div
		margin-top: 8vw
	.product-catalog-item-img-wrap
		width: 68vw
		margin: 0 auto
	.product-catalog-item-img
		+img-scale(100%)
		img
			object-fit: contain
	@media screen and ( min-width: 768px )
		display: flex
		align-items: center
		justify-content: space-between
		text-align: left
		> div
			width: calc(100% - 11.1111111111em)
			margin-top: 0
		& + .product-catalog-item
			margin-top: 3.125vw
		.product-catalog-item-img-wrap
			width: 8.8888888889em
	@media screen and ( min-width: 1280px )
		font-size: 18px
		& + .product-catalog-item
			margin-top: 40px

.product-impression-slider
	.swiper-slide
		width: 40.04004004004em
		padding: 0 2.2222222222em
		text-align: center
		.img-wrap
			width: 100%
			height: 26.6666666667em
			position: relative
			.img
				position: absolute
				top: 0
				right: 0
				bottom: 0
				left: 0
				overflow: hidden
				transition-duration: .3s
				transition-property: opacity
				text-align: center
				a
					display: block
					width: 100%
					height: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
		.title
			font-size: .7777777778em
			margin-top: 2.8571428571em
	@media screen and ( max-width: 767px )
		.swiper-slide
			width: 84.084084084084vw
			padding-right: 2vw
			padding-left: 2vw
			.img-wrap
				height: 56vw
			.title
				font-size: 3.2vw
				margin-top: 4vw

.product-features
	@media screen and ( min-width: 768px )
		.l-layout
			& + .l-layout
				margin-top: 4.4444444444em
				padding-top: 4.4444444444em
				border-top: 1px solid #f0f0f0

@keyframes bulletWidth
	0%
		width: 0
	100%
		width: 100%
