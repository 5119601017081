.p-zine-serial__count
    margin-top: 0
    padding: 4.4444444444em 0 2.2222222222em
    text-align: center
    width: 100%
    p
        font-size: 1.1111111111em
    @media screen and ( max-width: 767px )
        padding-top: 16vw
        padding-bottom: 8vw
        p
            font-size: 5.3333333333vw
