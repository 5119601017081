.section,.l-section
	width: 100%
	margin-left: auto
	margin-right: auto
	&.pb-0
		padding-bottom: 0 !important
	@media screen and ( min-width: 320px )
		&.has-background
			padding: 32vw 0
		& + .section, & + .l-section
			margin-top: 32vw
		&.section-xs, &.l-section--xs
			width: 60vw
		&.section-s, &.l-section--s
			width: 84vw
		&.section-m, &.l-section--m
			width: 84vw
		&.section-l, &.l-section--l
			width: 84vw
		&.section-xl, &.l-section--xl
			width: 84vw
	@media screen and ( min-width: 768px )
		&.has-background
			padding: 8.8888888889em 0
		& + .section, & + .l-section
			margin-top: 12.5vw
		&.section-xs,  &.l-section--xs
			width: 37.5vw
		&.section-s,  &.l-section--s
			width: 50vw
		&.section-m,  &.l-section--m
			width: 75vw
		&.section-l,  &.l-section--l
			width: 87.5vw
		&.section-xl,  &.l-section--xl
			width: 93.75vw
	@media screen and ( min-width: 1280px )
		& + .section, & + .l-section
			margin-top: 160px
		&.section-xs, &.l-section--xs
			width: 480px
		&.section-s, &.l-section--s
			width: 640px
		&.section-m, &.l-section--m
			width: 960px
		&.section-l, &.l-section--l
			width: 1120px
		&.section-xl, &.l-section--xl
			width: 1200px
