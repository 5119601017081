.news-top-selector
	text-align: right
	.news-top-selector-trigger
		font-size: r(14px)
		font-weight: 600
		position: relative
		z-index: 12
		em
			margin-left: r(11px)
			font-size: r(10px)
	.news-top-selector-list
		position: relative
		z-index: 11
		font-size: r(14px)
	.news-top-selector-list-container
		width: 280px
		padding: r(75px) r(30px) r(30px)
		position: absolute
		right: r(-30px)
		top: r(-45px)
		text-align: left
		z-index: 10
		font-size: r(14px)
		background-color: #fff
		display: none
	.news-top-selector-list-section
		line-height: 1.5
		ul
			li
				& + li
					margin-top: r(12px)
				&.current-cat
					> a
						font-weight: 600
			> li
				> .children
					margin-top: r(12px)
					.children
						margin-top: r(12px)
						li
							a
								padding-left: r(12px)
			> .children
				margin-top: r(12px)
		& + .news-top-selector-list-section
			margin-top: r(20px)
			padding-top: r(20px)
			border-top: 1px solid #dcdcdc
	@media screen and ( max-width: 767px )
		.news-top-selector-list
			z-index: auto
		.news-top-selector-list-container
			position: fixed
			top: 0
			left: 0
			overflow: hidden
			width: 100%
			height: 100%
			z-index: 600
			right: 0
			padding: 0
		.news-top-selector-list-wrapper
			width: calc(100% + 20px)
			height: 100%
			margin-right: 20px
			padding: 8vw calc(8vw + 20px) 8vw 8vw
			overscroll-behavior: none
			will-change: scroll-position
			scrollbar-width: none
			overflow-x: hidden
			overflow-y: scroll
		.news-top-selector-trigger
			border-radius: 1.6vw
			padding: 3.3333333333vw
			display: flex
			justify-content: center
			align-items: center
			box-shadow: 0 0 0 2px #e1e1e1 inset
		.news-top-selector-close
			top: 5vw
			right: 5vw
			font-size: 5.3333333333vw
			position: absolute
			display: block
