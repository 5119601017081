.magazine-item
	display: block
	.img
		+img-scale(133.333333%)
	h4
		margin-top: r(15px)
	.subtitle
		margin-top: r(10px)
		font-size: r(14px)
	.brief
		color: rgba(#fafafa , 0.5)
		margin-top: r(10px)
		font-size: r(14px)
	a
		color: #fafafa
		&:hover
			color: rgba(#fafafa , 0.65)
	&.magazine-normal
		a
			color: #191919
		.brief
			color: rgba(#191919 , 0.5)

.magazine-list-container
	@media screen and ( max-width: 767px )
		overflow: hidden
.magazine-list-wrap
	@media screen and ( max-width: 767px )
		overscroll-behavior: none
		will-change: scroll-position
		scrollbar-width: none
		overflow-x: scroll
		overflow-y: hidden
		width: 100vw
		margin-left: -4vw
		margin-right: 4vw
		.magazine-list
			width: 416vw
		.row
			flex-wrap: nowrap
			margin-left: 0
			margin-bottom: 0
			> *
				padding: 0 !important
				margin-right: 4vw
				&:last-child
					margin-right: 0
		.magazine-item
			width: 80vw
