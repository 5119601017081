.news-article-list
	ul
		li
			article
				time
					font-size: r(14px)
				h4
					margin-top: r(20px)
					a
						&:hover
							color: color(hover)
			& + li
				margin-top: r(40px)
				padding-top: r(40px)
				border-top: 1px solid rgba(25,25,25,.08)
	@media screen and ( max-width: 767px )
		ul
			li
				article
					h4
						margin-top: r(10px)
					
				& + li
					margin-top: r(20px)
					padding-top: r(20px)

.tagline
	display: flex
	flex-wrap: wrap
	margin-top: r(10px)
	a
		margin-top: r(20px)
		color: #191919
		box-shadow: 0 0 0 1px #191919 inset
		display: inline-flex
		align-items: center
		justify-content: center
		font-size: r(14px)
		padding: r(8px) r(16px)
		border-radius: 100px
		margin-right: r(8px)
		font-weight: 600
		line-height: 1
		&:hover
			background: #191919
			color: #fff
	@media screen and ( max-width: 767px )
		margin-top: r(5px)
		a
			margin-top: r(8px)