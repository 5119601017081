//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: 40px
$gl-gutter-vertical: 40px
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 4vw
	line-height: 1.2
	font-family: "Roboto", sans-serif
	color: color(body)
	@media (min-width: 768px)
		font-size: 1.40625vw
	@media (min-width: 1280px)
		font-size: 16px

main
	background: #f0f0f0

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none
	transition-duration: .15s
	transition-property: color,background-color,opacity
	text-decoration: none
	-webkit-tap-highlight-color: transparent
	&:hover
		color: color(hover)

.page-wrap
	padding-top: 32vw
	@media screen and ( min-width: 768px )
		padding-top: 12.5vw
	@media screen and ( min-width: 1280px )
		padding-top: 160px 
		font-size: 18px

