.product-lens-concept,.p-product-lens-concept
	.brief,header
		p
			display: flex
			align-items: center
			.c-icon
				font-size: 2.2222222222em
				margin-right: .25em
			span
				font-size: 1.6666666667em
				font-weight: 700
				position: relative
				top: .1166666667em
		h3
			line-height: 1.2
			margin-top: 2em
	.desc,.p-product-lens-concept__description
		line-height: 1.8
		margin-top: 2.2222222222em
	@media screen and ( max-width: 767px )
		.desc
			margin-top: 4vw
		.brief
			p
				.c-icon
					font-size: 8vw
					margin-right: 2vw
				span
					font-size: 6vw
			h3
				margin-top: 4vw

.product-performance-chart,.p-product-performance__chart
	h2
		border-bottom: 1px solid #191919
		padding-bottom: 4vw
	.product-performance-chart-figure,.p-product-performance__chart__figure
		margin-left: auto
		margin-right: auto
		text-align: center
		margin-top: 4vw
		.c-content-figure__wrapper
			&::before
				display: none !important
	figcaption
		font-size: 4vw
		margin-top: 8vw
	@media screen and ( min-width: 768px )
		h2
			padding-bottom: 1.5625vw

		.product-performance-chart-figure,.p-product-performance__chart__figure
			margin-top: 2.2222222222em
			width: 37.5vw !important
		figcaption
			font-size: 1.40625vw
			margin-top: 3.125vw
	@media screen and ( min-width: 1280px )
		.product-performance-chart-figure,.p-product-performance__chart__figure
			width: 480px !important
		h2
			padding-bottom: 20px
		figcaption
			font-size: 18px
			margin-top: 40px

.p-product-performance__chart__notes
	line-height: 1
	display: flex
	justify-content: center
	text-align: center
	margin-top: 8vw
	font-size: 3.2vw
	li
		display: flex
		align-items: center
		margin-left: 0
		padding-left: 0
		list-style: none
		> span
			display: inline-block
			box-sizing: border-box
		& + li
			margin-left: 4vw
			margin-top: 0 !important
	@media screen and ( min-width: 768px )
		margin-top: 1.1111111111em
		font-size: 1.09375vw
		li
			> span
				width: 1.40625vw
				height: 1.40625vw
				margin-right: .78125vw
			& + li
				margin-left: 2.2222222222em
	@media screen and ( min-width: 1280px )
		font-size: 14px
		li
			> span
				width: 18px
				height: 18px
				margin-right: 10px

.graph-legend
	display: table !important
	margin: 0 auto
	table-layout: fixed
	border-collapse: collapse
	border: 1px solid #191919
	text-align: center !important
	width: 100%
	font-size: calc(12 / 375 * 100vw)
	th,td
		border: 1px solid #191919
		padding: calc(10 / 375 * 100vw)
		line-height: 1.5em
	caption
		caption-side: bottom
		margin-top: 1em
	&.mtf
		max-width: calc(314 / 375 * 100vw)
	.mtf_10
		&.graph-legend_line
			background-color: #d9374b
		&.graph-legend_dotted
			i,&:before, &:after
				background-color: #d9374b
	.mtf_30
		&.graph-legend_line
			background-color: #00613c
		&.graph-legend_dotted
			i,&:before, &:after
				background-color: #00613c
	.vignetting_a
		&.graph-legend_line
			background-color: #0f3675
		&.graph-legend_dotted
			i,&:before, &:after
				background-color: #0f3675
	.vignetting_b
		&.graph-legend_line
			background-color: #d9374b
		&.graph-legend_dotted
			i,&:before, &:after
				background-color: #d9374b
	.vignetting_c
		&.graph-legend_line
			background-color: #00613c
		&.graph-legend_dotted
			i,&:before, &:after
				background-color: #00613c
	@media screen and ( min-width: 768px )
		font-size: 12px
		th,td
			padding: 10px
		&.mtf
			max-width: 314px

.graph-legend + p
	margin-top: 2em

.graph-legend_line
	height: calc(2 / 375 * 100vw)
	border-radius: calc(2 / 375 * 100vw)
	@media screen and ( min-width: 768px )
		height: 2px
		border-radius: 2px

.graph-legend_dotted
	position: relative
	&:before, &:after
		height: calc(2 / 375 * 100vw)
		border-radius: calc(2 / 375 * 100vw)
		width: calc((100% - (16 / 375 * 100vw)) / 3)
		content: ""
		position: absolute
		top: 0
	&:before
		left: 0
	&:after
		right: 0
	i
		display: block
		margin: 0 auto
		height: calc(2 / 375 * 100vw)
		border-radius: calc(2 / 375 * 100vw)
		width: calc((100% - (16 / 375 * 100vw)) / 3)
	@media screen and ( min-width: 768px )
		&:before, &:after
			height: 2px
			border-radius: 2px
			width: calc((100% - 16px) / 3)
		i
			height: 2px
			border-radius: 2px
			width: calc((100% - 16px) / 3)

.c-content-product_link
	> li
		display: flex
		justify-content: space-between
		& + li
			margin-top: 4vw
		> .c-content-figure
			margin: 0
			width: 24vw
			min-height: 24vw
			.c-content-figure__wrapper
				&::before
					padding-top: 100%
					content: ''
					display: block
		> div
			width: calc(100% - 28vw)
			h4,h5,h6
				font-size: 4vw
				a
					text-decoration: underline
			> div
				font-size: 3.2vw
				margin-top: 4vw
	@media screen and ( min-width: 768px )
		> li
			& + li
				margin-top: 1.1111111111em
			> .c-content-figure
				width: 9.375vw
				min-height: 9.375vw
			>div
				align-self: center
				width: calc(100% - 8.8888888889em)
				h4
					font-size: 1.3333333333em
				> div
					font-size: 1.09375vw
					margin-top: 3.125vw
	@media screen and ( min-width: 1280px )
		font-size: 18px
		> li
			> .c-content-figure
				width: 120px
				min-height: 120px
			> div
				> div
					font-size: 14px
					margin-top: 40px
