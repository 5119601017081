small
	font-size: 80%

.article-content,.p-article
	line-height: 1.8
	font-size: r(18px)
	.is-title
		text-align: center
		line-height: 1.2
	small
		display: block
		font-size: 80%
	ul
		list-style: disc
		padding-left: .8888888889em
		li
			& + li
				padding-top: 4vw
	> * + *
		margin-top: 4vw
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top
	* + h6
		margin-top: r(80px)
	h6 + *
		margin-top: r(40px)
	hr
		box-sizing: content-box
		overflow: visible
		height: 1px
		border: 0
		border-top: 1px solid #ccc
		margin: 1em 0
		padding: 0
	@media screen and ( max-width: 767px )
		* + h6
			margin-top: 16vw
		h6 + *
			margin-top: 8vw
	@media screen and ( min-width: 768px )
		> * + *
			margin-top: 1.5625vw
		ul
			list-style: disc
			li
				& + li
					padding-top: 1.5625vw
	@media screen and ( min-width: 1280px )
		> * + *
			margin-top: 20px
		ul
			list-style: disc
			li
				& + li
					padding-top: 20px

.c-content-hgroup
	.is-title
		text-align: center
		&.is-align--left
			text-align: left
	& + .c-content-richtext
		margin-top: 4vw
	@media screen and ( min-width: 768px )
		& + .c-content-richtext
			margin-top: 1.5625vw
	@media screen and ( min-width: 1280px )
		& + .c-content-richtext
			margin-top: 20px

.c-figure,.c-content-figure
	margin: 0 auto
	.c-figure-wrapper,.c-content-figure__wrapper
		position: relative
		width: 100%
		.c-figure-container,.c-content-figure__container
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			overflow: hidden
			transition-duration: .3s
			transition-property: opacity
			text-align: center
			img
				width: 100%
				height: 100%
				object-fit: contain
				vertical-align: bottom

.l-layout
	&.has-bg-color
		position: relative
		overflow: hidden
		padding-top: 32vw
		padding-bottom: 32vw
	@media screen and ( min-width: 768px )
		&.has-bg-color
			padding: 8.8888888889em 0

* + .l-layout
	margin-top: 8.8888888889em

.l-local
	& + .l-local
		margin-top: 48vw
		padding-top: 16vw
	@media screen and ( min-width: 768px )
		& + .l-local
			margin-top: 18.75vw
			padding-top: 6.25vw
	@media screen and ( min-width: 1280px )
		font-size: 18px
		& + .l-local
			margin-top: 240px
			padding-top: 80px

.l-local + .l-local.include-has-bg-color
	padding-top: 0

.l-content
	& + .l-content
		margin-top: 4vw
	@media screen and ( min-width: 768px )
		& + .l-content
			margin-top: 1.5625vw
	@media screen and ( min-width: 1280px )
		& + .l-content
			margin-top: 20px

.l-column
	& + .l-column
		margin-top: 16vw
	@media screen and ( min-width: 768px )
		& + .l-column
			margin-top: 6.25vw
	@media screen and ( min-width: 1280px )
		& + .l-column
			margin-top: 80px

.l-wrapper
	margin-left: auto
	margin-right: auto
	& + .l-wrapper
		margin-top: 16vw
	&.l-wrapper-50,&.l-wrapper--50
		width: 50%
	&.l-wrapper-75,&.l-wrapper--75
		width: 75%
	&.l-wrapper-33,&.l-wrapper--33
		width: 33.33%
	&.is-2col
		display: flex
		justify-content: space-between
		.l-column
			width: calc((100% - 4vw)*.5)
			& + .l-column
				margin-top: 0
	@media screen and ( min-width: 768px )
		& + .l-wrapper
			margin-top: 6.25vw
		&.is-2col
			.l-column
				width: calc((100% - 2.2222222222em)*.5)
	@media screen and ( min-width: 1280px )
		& + .l-wrapper
			margin-top: 80px

* + .l-accordion
	margin-top: 16vw
	@media screen and ( min-width: 768px )
		margin-top: 6.25vw
	@media screen and ( min-width: 1280px )
		margin-top: 80px

.l-accordion
	position: relative
	&.is-opened
		.l-accordion-wrapper,.l-accordion__wrapper
			height: auto
	.l-accordion-wrapper,.l-accordion__wrapper
		height: 8em
		padding-bottom: 6.66666667em
		overflow: hidden
	.l-accordion-button,.l-accordion__button
		position: absolute
		z-index: 10
		bottom: 0
		left: 0
		width: 100%
		text-align: center
	.l-accordion-veil,.l-accordion__veil
		position: absolute
		z-index: 10
		bottom: 0
		left: 0
		display: block
		width: 100%
		content: ""
		pointer-events: none
		height: 32vw
		background: linear-gradient(to bottom,rgba(250,250,250,0)0,rgba(250,250,250,.7) 50%,rgba(250,250,250,.85) 65%,rgba(250,250,250,.925) 75.5%,rgba(250,250,250,.963) 82.85%,rgba(250,250,250,.981) 88%,#fafafa 100%)
	@media screen and ( min-width: 768px )
		.l-accordion-veil,.l-accordion__veil
			height: 12.5vw
	@media screen and ( min-width: 1280px )
		.l-accordion-veil,.l-accordion__veil
			height: 160px

.c-center-desc
	font-size: 3.2vw
	margin-top: 4vw
	> div
		text-align: center
		> *
			display: inline-block
			text-align: left
	p + p
		margin-top: 1.11111111111em
	@media screen and ( min-width: 768px )
		font-size: .7777777778em
		margin-top: 2.8571428571em

.c-circle
	position: relative
	font-weight: 700
	top: .11328125em
	display: inline-block
	overflow: hidden
	width: 1em
	height: 1em
	text-align: center
	color: #fafafa
	border-radius: 50%
	background-color: #d9374b
	> span
		font-size: 68.1818181818%
		line-height: 1
		position: absolute
		top: 50%
		left: 50%
		transform: translateX(-50%) translateY(-58.8541666667%)
		letter-spacing: -.07em
		font-weight: 600
	&.is-number
		> span
			font-family: "SIGMA Sans","SystemSerif",sans-serif
			font-size: 75%
			transform: translateX(-50%) translateY(-41.1458333333%)
