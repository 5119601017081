h4,h5,h6
	font-weight: 600
	@media screen and ( min-width: 1280px )
		font-size: r(18px)
	@media screen and ( max-width: 767px )
		font-size: 4vw

h2,.h2
	font-size: r(30px)
	font-weight: 600
	@media screen and ( max-width: 767px )
		font-size: 7.2vw

h3,.h3
	font-size: r(20px)
	font-weight: 600
	@media screen and ( max-width: 767px )
		font-size: 5.3333333333vw
