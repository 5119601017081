.camera-top-series-wrap
	@media screen and ( min-width: 320px )
		& + .camera-top-series-wrap
			margin-top: 64vw
	@media screen and ( min-width: 768px )
		& + .camera-top-series-wrap
			margin-top: 17.7777777778em
	@media screen and ( min-width: 1280px )
		& + .camera-top-series-wrap
			margin-top: r(320px)

.camera-top
	.camera-list
		margin-right: auto
		margin-left: auto
	@media screen and ( min-width: 768px )
		.camera-list
			width: 68.75vw
	@media screen and ( min-width: 1280px )
		.camera-list
			width: 880px

.camera-list
	.row
		margin-bottom: r(-80px)
		> *
			padding-bottom: r(80px)
	@media screen and ( min-width: 1280px )
		.row
			margin-left: r(-80px)
			> *
				padding-left: r(80px)

.camera-item
	text-align: center
	.camera-upper
		display: block
	.camera-img
		+img-scale(39.7%)
	.camera-logo
		margin-top: r(40px)
		object
			width: 80px
			pointer-events: none
	.camera-lower
		margin-top: r(40px)
		line-height: 1
		text-decoration: underline
		font-size: r(18px)
		font-weight: 600
		a
			&:hover
				color: color(hover)
	.camera-glossary
		margin-top: r(40px)
		color: #888
	.camera-icon
		margin-top: r(30px)
		span
			display: inline-flex
			align-items: center
			justify-content: center
			background: #191919
			color: #fff
			height: r(24px)
			border-radius: 50px
			text-transform: uppercase
			font-size: r(14px)
			padding: 0 12px
			line-height: 1
	@media screen and ( max-width: 767px )
		.camera-logo,.camera-lower
			margin-top: r(30px)
		.camera-glossary
			margin-top: r(20px)
		.camera-icon
			margin-top: r(20px)

.accessories-relational-slider
	margin: 0 -20px
	.accessory-item
		figure
			+img-scale(100%)
			img
				object-fit: contain
		h4
			font-weight: 400
	.swiper-btn
		position: absolute
		top: 50%
		transform: translateY(-50%)
		cursor: pointer
		display: none
	.swiper-slide
		width: 40vw
		transform: translateX(4vw)
	@media screen and ( max-width: 767px )
		.accessory-item
			h4
				margin-top: 5.3333333333vw
				width: 32vw
				margin-right: auto
				margin-left: auto
	@media screen and ( min-width: 768px )
		.swiper-slide
			width: 12.5vw
			padding-right: 1.5625vw
			padding-left: 1.5625vw
			transform: none
		.swiper-btn
			display: block
			width: 2.34375vw
			height: 2.34375vw
			font-size: 2.34375vw
			&.swiper-prev
				left: -3.90625vw
			&.swiper-next
				right: -3.90625vw
		.accessory-item
			h4
				margin-top: 1.1111111111em
	@media screen and ( min-width: 1280px )
		.swiper-slide
			width: 200px
			padding-right: 20px
			padding-left: 20px
		.swiper-btn
			width: 30px
			height: 30px
			font-size: 30px
			&.swiper-prev
				left: -50px
			&.swiper-next
				right: -50px

.c-label
	display: inline-flex
	align-items: center
	justify-content: center
	background: #191919
	color: #fff
	height: r(24px)
	border-radius: 50px
	text-transform: uppercase
	font-size: r(14px)
	padding: 0 12px
	line-height: 1
