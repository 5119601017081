.pager
	.pagination
		display: flex
		align-items: center
		justify-content: center
		margin-left: -1.1111111111em
		li
			list-style: none
			display: inline-block
			text-align: center
			margin-left: 1.1111111111em
			a

				font-size: 3.2vw
				height: 8vw
				border-radius: .8888888889vw
				width: auto
				padding-right: .8333333333em
				padding-left: .8333333333em
				border: 1px solid transparent
				display: flex
				align-items: center
				justify-content: center
			&.active,&:hover
				a,span
					color: #fafafa
					background: color(black)
	@media screen and ( min-width: 768x )
		font-size: 18px
		.pagination
			li
				a
					font-size: 1.09375vw
					height: 3.125vw
					border-radius: .3125vw
	@media screen and ( min-width: 1280px )
		font-size: 18px
		.pagination
			li
				a
					font-size: 14px
					height: 40px
					border-radius: 4px
