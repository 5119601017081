.sub-banner
	.banner-item
		position: relative
		&[data-ui-color="dark"]
			.banner-title
				color: #fafafa
	.banner-img
		img
			width: 100%
	.banner-title
		position: absolute
		z-index: 50
		top: 0
		left: 0
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		height: 100%
		margin-top: 0
		transition-duration: .3s
		transition-property: color
		pointer-events: none
		text-align: center
	@media screen and ( min-width: 768px )
		.banner-item
			&.banner-560
				.banner-img
					+img-scale(43.75%)
	@media screen and ( min-width: 1280px )
		.banner-item
			&.banner-560
				.banner-img
					+img-scale(560px)

	@media screen and ( max-width: 767px )
		.banner-img
			+img-scale(133.333333%)
