nav.rank-math-breadcrumb 
	font-size: 14px
	p
		margin-bottom: -1em
		> *
			margin-right: 1.5625vw
			display: inline-block
			padding-bottom: 1em
	@media screen and ( max-width: 1279px )
		font-size: 1.09375vw
		> *
			margin-right: 1.5625vw
	@media screen and ( max-width: 767px )
		font-size: 3.2vw
		> *
			margin-right: 4vw

.breadcrumb-wrapper
	@media screen and ( max-width: 767px )
		margin-bottom: 12vw

nav.rank-math-breadcrumb p a:first-child
	font-size: 0

nav.rank-math-breadcrumb p a:first-child:before
	content: 'SIGMA'
	font-family: icomoon !important
	font-size: 14px
	@media screen and ( max-width: 1279px )
		font-size: 1.09375vw
	@media screen and ( max-width: 767px )
		font-size: 3.2vw