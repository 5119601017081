.product-detail-sidebar-container
	position: absolute
	z-index: 300
	top: 0
	left: 0
	width: 100%
	height: 100%
	pointer-events: none
	@media screen and ( min-width: 768px )
		top: -4.6875vw

.product-detail-sidebar-inner
	position: relative
	transform: translate(0, 0)
	will-change: position, transform

.product-detail-sidebar
	will-change: min-height
	&.is-affixed
		.product-detail-sidebar-inner
			width: 100% !important
	@media screen and ( min-width: 768px )
		&[data-ui-color="dark"]
			.sidebar-header
				h1
					color: #fafafa
			.sidebar-list 
				nav 
					ul 
						.nav-item.active 
							a
								color: #fafafa
			.button
				background: #fafafa
				color: #191919
		&.is-affixed
			.sidebar-list
				nav
					ul
						.lavalamp
							opacity: 1
	@media screen and ( max-width: 767px )
		&.is-affixed
			.sidebar-inner
				opacity: 1
				pointer-events: all

.sidebar-inner
	transition: 0.3s all
	@media screen and ( max-width: 767px )
		padding-right: 8vw
		padding-left: 8vw
		transition-duration: .3s
		transition-property: transform,opacity
		pointer-events: none
		opacity: 0
		background-color: #fff
	@media screen and ( min-width: 768px )
		padding-top: 3.125vw
	@media screen and ( min-width: 1280px )
		padding-top: r(40px)

.sidebar-list
	@media screen and ( min-width: 768px )
		display: inline-block
		max-width: 13.3333333333em
		padding: r(9px) 0 0 r(40px)
		pointer-events: all

.sidebar-button
	pointer-events: all
	p
		margin-bottom: 0 !important
	@media screen and ( min-width: 768px )
		position: absolute
		top: r(40px)
		right: r(40px)
		min-width: 8.8888888889em

.sidebar-header
	@media screen and ( max-width: 767px )
		display: flex
		align-items: center
		justify-content: space-between
		min-height: 14.6666666667vw
		h1
			width: 49.6vw
			font-size: 3.2vw
			padding-right: 15.7333333333vw
			flex-grow: 1
			position: relative
		.sidebar-trigger
			width: 11.7333333333vw
			font-size: 3.5555555556vw
			right: 4vw
			position: absolute
			top: 0
			display: flex
			align-items: center
			justify-content: center
			height: 100%
			cursor: pointer
			em
				transition: 0.3s all
			&.active
				em
					transform: rotate(180deg)

.sidebar-list
	@media screen and ( max-width: 767px )
		nav
			display: none
			ul
				padding-top: 4vw
				font-size: 3.2vw
				display: flex
				flex-wrap: wrap
				justify-content: space-between
				transition-duration: .3s
				margin-bottom: -6.6666666667vw
				> li
					width: 50%
					padding-bottom: 6.6666666667vw
					a
						display: inline-block
						margin: -.5em
						padding: .5em
						white-space: nowrap
						color: #888
					&.active
						a
							font-weight: 600
							color: #191919
	@media screen and ( min-width: 768px )
		display: inline-block
		max-width: 13.3333333333em
		padding: r(9px) 0 0 r(40px)
		pointer-events: all
		nav
			margin-top: r(40px)
			ul
				padding-left: 1rem
				position: relative
				.lavalamp
					position: absolute
					left: 0
					display: block
					width: 5px
					height: 5px
					content: ""
					transition-duration: .3s
					transition-property: top,opacity
					transform: translateY(1.5625vw)
					border-radius: 50%
					background-color: #191919
					opacity: 0
				.nav-item
					a
						display: inline-block
						margin: r(-9px)
						padding: r(9px)
						white-space: nowrap
						font-size: r(18px)
						color: #888
						transition: 0.3s all
					&.active
						a
							font-weight: 600
							color: #191919
					& + .nav-item
						margin-top: r(15px)
	@media screen and ( min-width: 1280px )
		font-size: 18px
		nav
			ul
				.lavalamp
					transform: translateY(0.888888em)
					
.product-detail-sidebar-container-static
	padding-top: 16vw
	nav
		ul
			margin-bottom: -6.6666666667vw
			li
				padding-bottom: 6.6666666667vw
				a
					font-size: 3.2vw

body:not(.ui-hidden)
	.product-detail-sidebar.is-affixed
		.sidebar-inner
			transform: translateY(5.5555555556em)
	@media screen and ( max-width: 767px )
		.product-detail-sidebar.is-affixed
			.sidebar-inner
				transform: translateY(20.4vw)
