.product-compare
	display: block
	background-color: #fafafa
	padding: 16vw
	.product-compare-header
		p
			margin-top: 2.2222222222em
			font-size: 1em
	@media screen and ( max-width: 767px )
		width: 100% !important
		.c-figure
			width: 116vw
			position: relative
			left: 50%
			transform: translateX(-50%)
	@media screen and ( min-width: 768px )
		padding: 4.4444444444em
	@media screen and ( min-width: 1280px )
		font-size: 18px
