.product-detail-banner-slider
	background: #fafafa
	.swiper-slide
		&[data-ui-color="light"]
			background: #fafafa
		&[data-ui-color="dark"]
			background: #000
	.banner-item
		position: relative
		.banner-zoom
			position: absolute
			z-index: 20
			display: flex
			align-items: center
			justify-content: center
			transition-duration: .3s
			transition-property: color,background-color
			color: #fff
			border-radius: 50%
			background-color: rgba(0,0,0,.05)
			cursor: pointer
		.banner-img
			+img-scale(133.333333%)
	.swiper-pagination
		font-size: 1px
		line-height: 1
		left: 50%
		display: flex
		align-items: flex-end
		transform: translateX(-50%)
		bottom: 8vw
		width: calc(100% - 37.3333333333vw)
		.swiper-pagination-bullet
			display: flex
			overflow: hidden
			align-items: flex-end
			flex-grow: 1
			width: auto
			height: 4px
			margin: 0
			opacity: 1
			border-radius: 2px
			background-color: transparent
			.swiper-pagination-bullet-progress
				display: block
				width: 100%
				height: 100%
				transition-duration: .3s
				background-color: rgba(25,25,25,.15)
				> span
					display: block
					width: 100%
					height: 100%
			& + .swiper-pagination-bullet
				margin-left: 4px
			&.swiper-pagination-bullet-active
				.swiper-pagination-bullet-progress
					> span
						background: #000
						animation-name: bulletWidth
						animation-duration: 8s
						animation-fill-mode: forwards
						animation-iteration-count: infinite
						animation-timing-function: linear
	&[data-ui-color="dark"]
		.swiper-pagination
			.swiper-pagination-bullet
				.swiper-pagination-bullet-progress
					background-color: rgba(250,250,250,.15)
				&.swiper-pagination-bullet-active
					.swiper-pagination-bullet-progress
						> span
							background: #fafafa

	@media only screen and (max-width: 767px)
		.banner-item
			.banner-zoom
				font-size: 4vw
				width: 10.6666666667vw
				height: 10.6666666667vw
				right: 4vw
				bottom: 2.6666666667vw
				transform: translateY(-2px)

	@media only screen and (min-width: 768px)
		.banner-item
			.banner-img
				+img-scale(56.25%)
			.banner-zoom
				width: 4.6875vw
				height: 4.6875vw
				right: 3.125vw
				bottom: 3.125vw
		.swiper-pagination
			bottom: 3.125vw
			width: 50vw
	@media only screen and (min-width: 1280px)
		.banner-item
			.banner-wrapper
				max-width: 1280px
				height: 720px
				margin: 0 auto
			.banner-zoom
				font-size: 20px
				width: 60px
				height: 60px
				right: 40px
				bottom: 40px
