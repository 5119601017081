.page-search
	.search-box
		position: relative
		&:before
			font-family: "Font Awesome 6 Pro"
			line-height: 1
			position: absolute
			top: 50%
			display: block
			width: 1em
			height: 1em
			content: "\f002"
			transform: translateY(-50%)
			text-align: center
			color: #d2d2d2
			font-size: r(26px)
			left: r(26px)
		input
			font-size: r(30px)
			padding: r(10.5px) r(60px)
			border-radius: 33px
			width: 100%
			line-height: 1
			border: 0
			background-color: #ebebeb
			&::placeholder
				font-weight: 600
				color: #d2d2d2

.section + .page-search
	margin-top: 11vw !important
	@media screen and ( min-width: 768px )
		margin-top: 32vw !important
	@media screen and ( min-width: 1280px )
		margin-top: r(160px) !important

.sub-banner + .page-search
	margin-top: 10.6666666667vw !important
	@media screen and ( min-width: 768px )
		margin-top: 4.44444444444vw !important
	@media screen and ( min-width: 1280px )
		margin-top: r(80px) !important

.top-product-nav
	margin-top: 16vw !important
	@media screen and ( min-width: 768px )
		margin-top: 6.25vw !important
	@media screen and ( min-width: 1280px )
		margin-top: r(80px) !important

.product-nav
	.nav-item
		font-size: r(18px)
		.img-wrapper
			width: r(120px)
			height: r(80px)
			margin: 0 auto
			.img-container
				padding-top: 66.666667%
				position: relative
			.img-inner
				position: absolute
				top: 0
				right: 0
				bottom: 0
				left: 0
				overflow: hidden
				transition-duration: .3s
				transition-property: opacity
				text-align: center
				img
					width: 100%
					height: 100%
					object-fit: contain
		h4
			margin-top: r(30px)
			text-align: center
	@media screen and ( max-width: 767px )
		.nav-item
			h4
				margin-top: r(16px)

.top-information
	ul
		li
			a
				text-decoration: underline
				text-decoration-color: #969696
				text-underline-offset: .1428571429em
				font-weight: 600
			& + li
				margin-top: r(30px)
	@media screen and ( max-width: 767px )
		width: 100% !important

.top-banners
	.rich-banner
		.rich-banner-img
			a
				+img-scale(133.333333%)

.top-support
	.support-item
		.icon
			font-size: r(80px)
		h4
			margin-top: r(20px)
		p
			margin-top: 1rem
			line-height: 1.8
			font-size: r(14px)

.top-sns
	ul
		li
			a
				display: block
				text-align: center
				em
					font-size: 2.7777777778em
					@media screen and ( max-width: 767px )
						font-size: 8vw
				h4
					margin-top: 1.1111111111em
					font-size: 18px
					@media screen and ( max-width: 1279px )
						font-size: 1.40625vw
					@media screen and ( max-width: 767px )
						font-size: 4vw
				&:hover
					color: color(hover)

.top-magazine
	@media screen and ( min-width: 1280px )
		font-size: 18px
		