.ui-hidden
	.global-header-wrap
		transform: translateY(-100%)

.global-header
	position: fixed
	z-index: 500
	top: 0
	left: 0
	width: 100%
	pointer-events: none
	transition: 0.3s all
	.header-container
		width: 100%
		pointer-events: all
	&.on-following
		.global-header-wrap
			background: #fff

	&[data-ui-color="dark"]
		color: #fafafa
		&.on-following
			color: color(body)
			.logo
				img
					filter: none
			@media screen and ( min-width: 768px )
				.menu-toggle,.search-toggle,.header-sub-menu-toggle
					background-color: rgba(0,0,0,.05)
			.menu-wrapper
				.menu
					ul
						li
							a:hover
								color: color(hover)
		.logo
			img
				filter: brightness(0) invert(1)
				transition: 0.3s all
		.menu-wrapper
			.menu
				ul
					li
						a:hover
							color: rgba(#fff , 0.65)
		.menu-toggle,.search-toggle,.header-sub-menu-toggle
			background: rgba(255,255,255,.05)
	@media screen and ( min-width: 768px )
		.header-container
			max-width: 93.75vw
			margin: 0 auto
		&[data-ui-color="dark"]
			.header-sub-menu
				background: #000
				.is-main
					> li
						a
							&:hover
								color: rgba(#fff , 0.65)
			&.on-following
				.header-sub-menu
					background: #fff
					.is-main
						> li
							a
								&:hover
									color: color(hover)
	@media screen and ( min-width: 1280px )
		.header-container
			max-width: 1200px

.global-header-wrap
	pointer-events: none
	transition-duration: .3s
	padding: 8vw
	@media screen and ( min-width: 768px )
		padding: 3.125vw
	@media screen and ( min-width: 1280px )
		padding: 20px 40px

.logo
	font-size: 18px
	@media screen and ( max-width: 1279px )
		font-size: 1.40625vw
	@media screen and ( max-width: 767px )
		font-size: 5.3333333333vw
	img
		height: 100%

.header-wrap
	position: relative
	z-index: 10
	@media screen and ( min-width: 1280px )
		margin-left: r(-30px)
		> *
			padding-left: r(30px)

.menu-wrapper
	flex: 1
	.menu
		ul
			display: flex
			align-items: center
			justify-content: center
			margin-left: r(-30px)
			li
				padding-left: r(30px)
				a
					padding: 0 1rem
					display: inline-block
					&:hover
						color: color(hover)
	@media screen and ( max-width: 1280.98px )
		.menu
			ul
				margin-left: r(-20px)
				li
					padding-left: r(20px)
					a
						font-size: 14px
	@media screen and ( max-width: 767px )
		.menu
			ul
				margin-left: 0
				display: block
				li
					padding-left: 0
					& + li
						margin-top: 6.6666666667vw
					a
						font-size: 4vw
						padding: 0

.search-toggle,.menu-toggle,.header-sub-menu-toggle
	height: r(50px)
	width: r(50px)
	border-radius: 100%
	background-color: rgba(0,0,0,.05)
	display: flex
	align-items: center
	justify-content: center
	cursor: pointer
	transition: .15s all
	font-size: 18px
	@media screen and ( max-width: 1279px )
		font-size: 1.40625vw
	@media screen and ( max-width: 767px )
		font-size: 5.3333333333vw
		width: auto
		height: auto
		background-color: transparent
	@media screen and ( min-width: 768px )
		&:hover
			background-color: rgba(0,0,0,.1)

.social-list
	display: flex
	li
		& + li
			margin-left: r(30px)

.header-sub-menu
	.is-main
		font-size: r(14px)
		> li
			white-space: nowrap
			& + li
				margin-top: r(20px)
			a
				&:hover
					color: color(hover)
	@media screen and ( max-width: 767px )
		margin-top: 6.6666666667vw
		padding-top: 6.6666666667vw
		border-top: 1px solid #f5f5f5
		.is-main
			font-size: 4w
			display: flex
			flex-wrap: wrap
			> li
				width: 50%
				margin-bottom: 6.6666666667vw
				margin-top: 0 !important
	@media screen and ( min-width: 768px )
		display: none
		opacity: 0
		position: absolute
		z-index: 30
		right: 0
		background-color: #fff
		top: 5.5vw
		width: 25vw
		padding: 3.125vw
	@media screen and ( min-width: 1280px )
		top: 64.5px
		width: 320px
		padding: 40px

.search-modal
	position: absolute
	background-color: #fff
	z-index: 11
	display: none
	box-sizing: border-box
	transition-duration: .3s
	transition-property: color,background-color
	pointer-events: all
	opacity: 0
	top: 100%
	right: auto
	width: 100vw
	padding: 2.5vw 3.125vw .9375vw
	.search-box
		display: flex
		align-items: center
		em
			font-size: 20px
			line-height: 1
			color: #d2d2d2
	.search-icon
		margin-right: 10px
	.search-close
		margin-left: 10px
		cursor: pointer
		em
			font-size: 24px
	.search-input
		flex: 1
		input
			min-height: 34px
			width: 100%
			border: 0
			font-size: 20px
			&::placeholder
				font-weight: 600
				color: #d2d2d2
	.search-suggest
		padding-top: 20px
	@media screen and ( min-width: 768px )
		top: 1.5625vw
		right: 1.5625vw
		width: 43.75vw
		padding: 2.5vw 3.125vw .9375vw
	@media screen and ( min-width: 1280px )
		top: 20px
		right: 20px
		width: 560px
		padding: 32px 40px 12px

.menu-mobile-offcanvas
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100vh
	transform: translateX(-100%)
	background: #fff
	z-index: 11
	&.is-open
		transform: translate(0,0,0)
	@media screen and ( min-width: 1024.98px )
		display: none
	.offcanvas-close
		display: none
	.offcanvas-wrap
		padding: 24vw 8vw
		position: relative

.offside-js--is-open
	overflow: hidden

.overlay,.offcanvas-overlay
	position: fixed
	top: 0
	left: 0
	display: none
	width: 100%
	background-color: rgba(0,0,0,.1)
	z-index: 10
	height: 100vh
	pointer-events: all

.offcanvas-overlay
	pointer-events: none

.sigma-global-tab
	position: fixed
	z-index: 400
	bottom: 0
	left: 0
	width: 100%
	transform: translateY(100%)
	@media screen and ( min-width: 768px )
		display: none
	ul
		line-height: 1
		display: flex
		transition-duration: .3s
		transition-property: transform
		transform: translateY(-100%)
		background-color: rgba(255,255,255,.9)
		height: 21.3333333333vw
		li
			width: 20%
			a
				display: block
				width: 100%
				padding: 4.2666666667vw 0
				text-align: center
				.icon
					display: block
					width: 10.1333333333vw
					height: 7.4666666667vw
					margin: 0 auto
				.text
					display: block
					font-size: 3.2vw
					margin-top: 2.1333333333vw

body.ui-hidden .sigma-global-tab ul
	transform: translateY(0)
