$threshold: 60%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $colors
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(body), color(white))
		&:hover
			background-color: darken($value, 5%)
	.btn-border-#{$color}
		border-style: solid
		border-width: 1px
		border-color: $value
		&:hover
			background-color: #{$color}
			color: contrast-color($value, color(body), color(white))

.button-link
	text-decoration: underline
	font-weight: 600
	font-size: 4vw
	&:hover
		color: color(hover)
	@media screen and ( min-width: 768px )
		font-size: 1.5625vw
	@media screen and ( min-width: 1280px )
		font-size: 20px

.buttons,.c-buttons
	text-align: center
	p
		width: 100%
	.button,.c-button
		&.block,&.is-block
			& + .block, & + .is-block
				margin-top: 1.1111111111em

* + .buttons
	margin-top: 8vw

@media screen and ( min-width: 768px )
	* + .buttons
		margin-top: 2.2222222222em

.button,.c-button
	display: inline-flex
	align-items: center
	justify-content: center
	background-color: #191919
	border-radius: 20px
	color: #fafafa
	font-weight: 600
	line-height: 1
	padding: 11px 40px
	text-align: center
	&:hover
		background-color: rgba(25,25,25,.65)
		color: #fafafa
	&.button-border,&.is-color--text-black
		background-color: transparent
		border: 1px solid #191919
		color: #191919
		&:hover
			background-color: #191919
			color: #fff
		&.is-white,&.is-color--text-white
			color: #fafafa
			border-color: #fafafa
			&:hover
				background-color: #fafafa
				color: #191919
	@media screen and ( min-width: 768px )
		font-size: 18px
	@media screen and ( max-width: 767px )
		padding: .6666666667em 1.1666666667em
		border-radius: 1000px
		display: inline-block
		font-size: 4vw

.button-big,.is-type--rectangle
	display: block
	text-align: center
	font-size: 1em
	background: #191919
	color: #fafafa
	transition: 0.3s all
	padding-top: 21.3333333333vw
	padding-bottom: 21.3333333333vw
	font-weight: 600
	border-radius: 1.7777777778vw
	&:hover
		background: rgba(25,25,25,.65)
		color: #fafafa
	&-border,&.is-color--text-black
		background: transparent
		border: 1px solid #191919
		color: #191919
		padding: 2em .6666666667em
		&:hover
			background: #191919
			color: #fafafa
	@media screen and ( min-width: 768px )
		padding-top: 6.25vw
		padding-bottom: 6.25vw
		border-radius: .625vw
		br
			display: none
		&-border
			padding: 2.2222222222em .5555555556em
	@media screen and ( min-width: 1280px )
		border-radius: 8px
		padding-top: 80px
		padding-bottom: 80px
		font-size: 18px
		&-border
			padding: 2.2222222222em .5555555556em

.c-glossary
	.c-icon
		margin-right: .3333333333em
	.c-glossary-trigger
		line-height: 1
		text-decoration: underline
		font-weight: 600
