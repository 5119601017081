.global-footer
	background: #f0f0f0
	@media screen and ( max-width: 767px )
		.section
			& + .section
				margin-top: 0 !important

.footer-menu
	ul
		li
			a
				&:hover
					color: color(hover)
			& + li
				margin-top: r(30px)
	font-size: 18px
	@media screen and ( max-width: 1279px )
		font-size: 1.40625vw
	@media screen and ( max-width: 767px )
		font-size: 5.3333333333vw
	&.footer-menu-2
		@media screen and ( max-width: 1279px )
			font-size: 1.09375vw
		@media screen and ( max-width: 767px )
			font-size: 3.2vw

.footer-policy
	ul
		li
			a
				&:hover
					color: color(hover)
			& + li
				margin-top: r(30px)
	font-size: 14px
	@media screen and ( max-width: 1279px )
		font-size: 1.09375vw
	@media screen and ( max-width: 767px )
		font-size: 3.2vw

.footer-social
	@media screen and ( max-width: 767px )
		.social-list
			justify-content: flex-start !important

.language
	ul
		display: flex
		align-items: center
		.wpml-ls-current-language
			font-weight: 700
	.wpml-ls-legacy-list-horizontal > ul
		margin: 0 -20px !important