.home-banner
	.banner-item
		display: block
	.banner-video
		pointer-events: none
	.banner-inner
		+img-scale(560px)
	@media screen and ( max-width: 1280.98px )
		.banner-inner
			+img-scale(43.75%)
	@media screen and ( max-width: 767px )
		.banner-inner
			+img-scale(133.3333333333%)