
.lens-search-container
	position: relative
	h4,h5,h6
		&.has-value
			display: flex
			align-items: center
			justify-content: space-between
	#result_sum
		font-size: 1.1111111111em
		display: none
		padding-bottom: 4.4444444444em
		text-align: center
	.lens-search-side-footer
		a
			opacity: 0.5
			pointer-events: none
			display: inline-block
			margin: -1em
			padding: 1em
			&.is-active
				opacity: 1
				pointer-events: all
	@media screen and ( max-width: 767px )
		.lens-search-side
			position: fixed!important
			z-index: 1000
			top: 0
			left: 0
			display: none
			overflow: hidden
			width: 100%
			height: 100%!important
			will-change: min-height
		.lens-search-side-inner
			position: fixed!important
			top: 0!important
			width: 100%!important
			height: 100%
			transform: translate3d(0,0,0)!important
			background: #fafafa
		.lens-search-side-wrapper
			padding-top: 8vw
			padding-bottom: 28vw
			padding-left: 8vw
			height: calc(100% - 36vw)
			padding-right: 8vw
			transform: translateY(0)!important
			will-change: scroll-position
			scrollbar-width: none
			overflow-x: hidden
			overflow-y: scroll
		.lens-search-side-header
			position: relative
			.lens-search-side-header-title
				font-size: 6.4vw
				margin-bottom: 10.6666666667vw
				text-align: center
			.lens-search-side-header-close
				font-size: 5.3333333333vw
				position: absolute
				top: 50%
				right: 0
				transform: translateY(-50%)
		.search-cond
			& + .search-cond-wrapper, & + .search-cond
				margin-top: 8vw
			h4,h5,h6
				font-weight: 400
				font-size: 4vw
				&.has-value
					> span
						width: calc((100% - 4vw)*.5)
						&.is-value
							display: flex
							.body
								flex-grow: 1
							.c-icon
								font-size: 3.7333333333vw
								padding-left: 1em
				& + .search-cond-toggle-content, & + .search-cond-toggle, & + ul
					padding-top: 4vw
			ul
				> li
					& + li
						margin-top: 4vw
			.search-cond-toggle-content
				h6
					margin-top: 8vw
					
		.search-cond-toggle
			.search-cond-toggle-content
				display: none

		.search-cond-wrapper
			& + .search-cond
				margin-top: 8vw
			.search-cond
				margin-top: 8vw

		.lens-search-side-footer
			padding: 8vw
			line-height: 1
			position: fixed
			z-index: 100
			bottom: 0
			left: 0
			display: flex
			align-items: center
			box-sizing: border-box
			width: 100%
			background-color: #191919
			#view
				margin-left: 9.0666666667vw
				padding-left: 9.0666666667vw
				position: relative
				flex-grow: 1
				text-align: center
				&:before
					position: absolute
					top: 0
					left: 0
					display: block
					width: 1px
					height: 100%
					content: ""
					text-align: center
					background-color: rgba(250,250,250,.2)
			a
				color: #969696
				&.is-active
					color: #fafafa
	@media screen and ( min-width: 768px )
		display: flex
		align-items: stretch
		justify-content: space-between
		font-size: 18px
		.lens-search-main
			width: 48.8888888889em
			margin-right: 2.2222222222em
		.lens-search-side
			position: relative
			width: 13.3333333333em
		.lens-search-side-header
			display: none
		.lens-search-side-footer
			padding-bottom: 3.125vw
			font-size: .7777777778em
			margin-top: 2.8571428571em
			text-align: center
			a
				&.is-active
					color: #191919
			#clear
				.c-icon
					margin-right: 1em
		.lens-search-side-wrapper
			padding-top: 3.125vw
		.search-cond
			ul
				margin-bottom: 1.953125vw
				> li
					& + li
						margin-top: 1.171875vw
			& + .search-cond
				margin-top: 4.6875vw
			& + .search-cond-wrapper
				margin-top: 4.6875vw
		h4,h5,h6
			font-size: 1.09375vw
			& + .search-cond-toggle-content
				.search-cond-toggle-content-body
					padding-top: 1.171875vw
			& + ul
				margin-top: 1.171875vw
			> .is-value
				display: none
		.search-cond-wrapper
			.search-cond
				margin-top: 1.953125vw
				margin-left: 2.5vw
				h5
					margin-left: -2.5vw
			& + .search-cond
				margin-top: 4.6875vw
	@media screen and ( min-width: 1280px )
		.lens-search-main
			padding-top: 40px
			padding-bottom: 40px
		.lens-search-side-wrapper
			padding-top: 40px
		.search-cond
			ul
				margin-bottom: 25px
				> li
					& + li
						margin-top: 15px
			& + .search-cond
				margin-top: 60px
			& + .search-cond-wrapper
				margin-top: 60px
		h4,h5,h6
			font-size: 14px
			& + .search-cond-toggle-content
				.search-cond-toggle-content-body
					padding-top: 15px
			& + ul
				margin-top: 15px
			> .is-value
				display: none
		.search-cond-wrapper
			.search-cond
				margin-top: 25px
				margin-left: 32px
				h5
					margin-left: -32px
			& + .search-cond
				margin-top: 60px
		.lens-search-side-footer
			padding-bottom: 40px

.lens-search-side-inner
	transform: translate3d(0,0,0)
	will-change: position,transform
	> *
		transition-duration: .3s
		transition-property: transform

.lens-search-concept
	& + .lens-search-concept
		padding-top: 4.4444444444em
	.lens-concept
		line-height: 1
		border-bottom: 1px solid #191919
		> i
			color: #666
		h2
			font-size: 1.6666666667em
			line-height: 1
			color: #666
	@media screen and ( max-width: 767px )
		.lens-concept
			padding-bottom: 8vw
			text-align: center
			> i
				font-size: 10.6666666667vw
			h2
				margin-top: 2.6666666667vw
				font-size: 7.2vw
	@media screen and ( min-width: 768px )
		.lens-concept
			display: flex
			align-items: center
			padding-bottom: 1.5625vw
			> i
				font-size: 3.90625vw
				+ h2
					margin-left: 1.171875vw
		> ul
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			li
				width: calc((100% - 2.2222222222em)*.5)
	@media screen and ( min-width: 1280px )
		font-size: 18px
		.lens-concept
			padding-bottom: 20px
			> i
				font-size: 50px
				+ h2
					margin-left: 15px

.lens-link
	display: flex
	align-items: center
	text-decoration: none
	position: relative
	width: 100%
	border-bottom: 1px solid #e1e1e1
	padding: 5.3333333333vw 0 calc(1px + 5.3333333333vw)
	.lens-image-wrapper
		border-left: 1px solid #e1e1e1
		width: 24vw
		margin-left: 4vw
		padding-left: 4vw
	.lens-image
		+img-scale(100%)
	.lens-link-code
		font-weight: 700
		text-align: center
		font-size: 5.3333333333vw
		width: 8vw
	.lens-link-name
		margin-left: 4vw
		width: calc(100% - 40vw)
	@media screen and ( min-width: 768px )
		padding: 1.1111111111em 0 calc(1px + 1.1111111111em)
		.lens-link-name
			margin-left: 1.5625vw
			width: calc(100% - 11.1111111111em)
			h4
				font-size: 1.40625vw
		.lens-image-wrapper
			width: 7.8125vw
			margin-left: 1.5625vw
			padding-left: 1.5625vw
		.lens-link-code
			font-size: 1.5625vw
			width: 3.125vw
	@media screen and ( min-width: 1280px )
		.lens-image-wrapper
			width: 120px
			margin-left: 20px
			padding-left: 20px
		.lens-link-code
			font-size: 20px
			width: 40px
		.lens-link-name
			margin-left: 20px
			h4
				font-size: 18px

.lens-search-footer
	display: none
	@media screen and ( max-width: 767px )
		line-height: 1
		position: fixed
		z-index: 450
		bottom: 0
		left: 0
		width: 100%
		display: block
		> div
			padding: 4vw 8vw
			line-height: 1
			transition-duration: .3s
			transition-property: transform
			text-align: center
			background-color: #191919
			will-change: transform
			a
				color: #fafafa
				display: block

body:not(.ui-hidden) .lens-search-footer > div
	@media screen and ( max-width: 767px )
		transform: translateY(-21.3333333333vw)

label.c-checkbox
	cursor: pointer
	input
		display: none
		&:checked
			~ span
				.c-icon
					&.c-icon--check_on
						display: inline-block
					&.c-icon--check_off
						display: none
	>
		span
			display: inline-flex
			align-items: center
			.c-icon
				color: #dcdcdc
				&.c-icon--check_on
					display: none
	@media screen and ( max-width: 767px )
		>
			span
				.c-icon
					margin-right: 4vw
					font-size: 10.6666666667vw

	@media screen and ( min-width: 768px )
		>
			span
				font-size: 1.25vw
				.c-icon
					margin-right: .78125vw
					font-size: 1.5625vw
	@media screen and ( min-width: 1280px )
		>
			span
				font-size: 16px
				.c-icon
					margin-right: 10px
					font-size: 20px
