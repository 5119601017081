.contact-wrap
	margin-top: 0
	.row
		height: 100%
	.tagline
		a
			padding: .6111111111em 1.1111111111em
			color: #fafafa
			background: #b4b4b4
			box-shadow: none
	.contact-info
		padding: 8vw
		dl
			display: flex
			flex-wrap: wrap
			justify-content: space-between
			dt
				width: 18vw
				margin-top: 4vw
			dd
				margin-top: 4vw
				width: calc(100% - 18vw)
	@media screen and ( min-width: 768px )
		background: #fff
		margin-top: -12.5vw
		height: 40em
		.contact-info
			padding: 3.125vw
			dl
				margin-top: 1.5625vw
				dt
					margin-top: 1.5625vw
					width: 7.8125vw
				dd
					width: calc(100% - 5.5555555556em)
					margin-top: 1.5625vw
		.is-left
			display: flex
			align-items: center
			width: 50%
			background-color: #fff
		.is-right
			display: flex
			align-items: center
			width: 50%
			background-color: #fafafa
	@media screen and ( min-width: 1280px )
		margin-top: -160px
		.is-left
			width: 40%
		.is-right
			width: 60%
		.contact-info
			padding: 40px
			dl
				margin-top: 20px
				dt
					width: 100px
					margin-top: 20px
				dd
					margin-top: 20px

.contact-form
	padding: 8vw
	background: #fff
	.form-group
		.wpcf7-not-valid-tip
			font-size: 80%
			display: block
			margin-top: 0.444444em
		input[type="text"], input[type="email"], input[type="tel"],textarea,select
			border: 1px solid #b4b4b4
			max-width: 100%
			padding: r(15px) r(10px)
			border-radius: 3px
			width: 100%
		
	h2
		margin-bottom: 4vw
	.row
		margin-bottom: -4vw
		margin-left: -4vw
		> *
			padding-bottom: 4vw
			padding-left: 4vw
	@media screen and ( min-width: 768px )
		background: transparent
		.row
			margin-bottom: -1.5625vw
			margin-left: -1.5625vw
			> *
				padding-bottom: 1.5625vw
				padding-left: 1.5625vw
		padding: 3.125vw
		h2
			margin-bottom: 1.5625vw
	@media screen and ( min-width: 1280px )
		padding: 40px
		.row
			margin-bottom: -20px
			margin-left: -20px
			> *
				padding-bottom: 20px
				padding-left: 20px
		h2
			margin-bottom: 20px

.contact-map
	iframe
		width: 100%
		height: 600px
