.accessories-nav
	ul
		li
			display: flex
			align-items: center
			.c-icon
				font-size: 7.4666666667vw
				margin-right: 2.6666666667vw
			span
				flex-grow: 1
			& + li
				margin-top: 4vw
				padding-top: 4vw
				border-top: 1px solid #e1e1e1
	@media screen and ( min-width: 768px )
		ul
			li
				.c-icon
					font-size: 2.5vw
					margin-right: .78125vw
				& + li
					margin-top: 1.1111111111em
					padding-top: 1.1111111111em
	@media screen and ( min-width: 1280px )
		font-size: 18px
		ul
			li
				.c-icon
					font-size: 32px
					margin-right: 10px

.accessory-top-list
	margin-bottom: -16vw
	margin-left: -16vw
	.accessory-top-item
		margin-bottom: 16vw
		margin-left: 16vw
		.c-figure
			.c-figure-wrapper
				padding-top: 100%
		h4
			margin-top: 4vw
			text-align: center
	@media screen and ( min-width: 768px )
		display: flex
		flex-wrap: wrap
		justify-content: center
		margin-bottom: -4.4444444444em
		margin-left: -4.4444444444em
		.accessory-top-item
			width: 14.8148148148em
			margin-bottom: 4.4444444444em
			margin-left: 4.4444444444em
			h4
				margin-top: 1.1111111111em
	@media screen and ( min-width: 1280px )
		font-size: 18px
