.p-product-grid__list
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	.p-product-grid__item
		width: calc((100% - 4vw)*.5)
		margin-top: 4.4444444444em
		text-align: center
		h4
			margin-top: 8vw
		.p-product-grid__item__footer
				display: inline-block
				margin-top: 4vw
				text-align: left

		.c-content-figure
			.c-content-figure__wrapper
				&:before
					display: block
					padding-top: 100%
					content: ""
		&:nth-child(1),&:nth-child(2)
			margin-top: 0
	@media screen and ( min-width: 768px )
		.p-product-grid__item
			width: 15.5555555556em
			.p-product-grid__item__footer
				margin-top: 2.2222222222em
			h4
				margin-top: 2.2222222222em
	@media screen and ( min-width: 1280px )
		font-size: 18px
